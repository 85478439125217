import React from "react"
import { Cents } from "../../lib/currency"
import accounting from "accounting"

const Currency = ({
  amount,
  empty,
}: {
  amount: Cents | null
  empty?: string
}) => {
  if (amount == null) return <>{empty || ""}</>
  const formatted = accounting.formatMoney(amount.amount() / 100)
  return <>{formatted}</>
}

export default Currency
