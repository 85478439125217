import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import Img, { FixedObject } from "gatsby-image"
import Currency from "../components/currency"
import { default as MakeCurrency } from "../../lib/currency"
import {
  GalleryPageBySlugQuery,
  MarkdownRemarkFrontmatter,
  Maybe,
  GatsbyImageSharpFixed_TracedSvgFragment,
} from "../generated/graphql"
import idx from "idx"

type galleryItem = Pick<
  MarkdownRemarkFrontmatter,
  "slug" | "name" | "price"
> & {
  image?: Maybe<{
    childImageSharp?: Maybe<{
      fixed?: Maybe<GatsbyImageSharpFixed_TracedSvgFragment>
    }>
  }>
}

type galleryItemNameToItem = { [key: string]: galleryItem }

const PageTemplate = ({ data }: { data: GalleryPageBySlugQuery }) => {
  const allItems = idx(data, (_) => _.galleryItems.nodes) || []

  const galleryItemNameToItem: galleryItemNameToItem = allItems.reduce(
    (m: galleryItemNameToItem, i) => {
      const name = idx(i, (_) => _.frontmatter.name)
      if (typeof name != "string") return m
      m[name] = i.frontmatter
      return m
    },
    {}
  )

  const galleryItems =
    idx(data, (_) => _.markdownRemark.frontmatter.galleryItems) || []
  const html = idx(data, (_) => _.markdownRemark.html) || ""
  const gallerySlug = idx(data, (_) => _.markdownRemark.frontmatter.slug)

  return (
    <Fragment>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <div className="flex flex-row flex-wrap justify-around -mx-4 -my-4">
        {galleryItems.map((item, i: number) => {
          if (!item) return <div key={i} />
          const slug = idx(galleryItemNameToItem, (_) => _[item].slug)
          const name = idx(galleryItemNameToItem, (_) => _[item].name)
          const price = idx(galleryItemNameToItem, (_) => _[item].price) || 0
          const image = idx(
            galleryItemNameToItem,
            (_) => _[item].image.childImageSharp.fixed
          )
          const fixedImage = image as FixedObject
          return (
            <div
              key={i}
              className="flex flex-col px-4 py-4 text-center flex-wrap"
            >
              <Link to={`/${gallerySlug}/${slug}`}>
                {fixedImage && <Img className="shadow-md" fixed={fixedImage} />}
                <div className="text-bm-blue-500 hover:text-bm-blue-500 active:text-bm-blue-500 text-shadow w-auto flex-shrink">
                  {name}
                </div>
                <div className="text-bm-blue-500 hover:text-bm-blue-500 active:text-bm-blue-500 text-shadow w-auto flex-shrink">
                  <Currency amount={MakeCurrency.fromDollars(price)} />
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

export const query = graphql`
  query GalleryPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        slug
        title
        galleryItems
      }
    }
    galleryItems: allMarkdownRemark(
      filter: {
        frontmatter: {
          category: { eq: $slug }
          template: { eq: "item" }
          draft: { ne: true }
        }
      }
    ) {
      nodes {
        frontmatter {
          slug
          name
          price
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
