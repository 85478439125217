const Currency = (amount: number): Cents => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return new Cents(amount)
}

export class Cents {
  private _amount: number

  public constructor(amount: number) {
    this._amount = Math.round(amount)
  }

  public amount(): number {
    return this._amount
  }

  public add(cents: Cents): Cents {
    return Currency(this.amount() + cents.amount())
  }

  public mult(val: Cents | number): Cents {
    if (val instanceof Cents) {
      return Currency(this.amount() * val.amount())
    } else {
      return Currency(this.amount() * val)
    }
  }

  public eq(cents: Cents): boolean {
    return this.amount() == cents.amount()
  }
}

Currency.fromDollars = (amount: number): Cents => {
  return Currency(amount * 100)
}

export default Currency
